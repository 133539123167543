<!--
 * @Description: 企业资料审核
 * @Author: 琢磨先生
 * @Date: 2023-12-16 11:44:25
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-18 12:19:19
-->
<template>
  <el-dialog
    title="公司资料审核"
    v-model="visible"
    width="800"
    draggable
    @closed="onClosed"
  >
    <el-alert type="error" :closable="false" title=""
      >请认真核实印业执照上公司名称与注册公司名称是否相同！</el-alert
    >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140"
      v-loading="loading"
    >
      <el-form-item label="公司名称">{{ model.companyName }}</el-form-item>
      <el-form-item label="法人">{{ model.personName }}</el-form-item>
      <el-form-item label="法人电话">{{ model.personMobile }}</el-form-item>
      <el-form-item label="主账号">{{ model.adminName }}</el-form-item>
      <el-form-item label="主账号手机">{{ model.adminMobile }}</el-form-item>

      <el-form-item label="证件资料">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-around;
          "
        >
          <div style="margin-right: 10px">
            <el-image
              :src="`${model.licenseImgUrl}`"
              class="certImage"
              fit="fill"
              :initial-index="0"
              :preview-src-list="srcList"
              alt="缺失"
            >
              <template #error>
                <div class="image-slot">
                  <el-icon><WarnTriangleFilled /></el-icon>
                  <div style="line-height: normal">缺失</div>
                </div>
              </template>
            </el-image>
            <p style="text-align: center">印业执照</p>
          </div>
          <div style="margin-right: 10px">
            <el-image
              :src="`${model.kaihuImgUrl}`"
              class="certImage"
              fit="fill"
              :initial-index="1"
              :preview-src-list="srcList"
              alt="缺失"
            >
              <template #error>
                <div class="image-slot">
                  <el-icon><WarnTriangleFilled /></el-icon>
                  <div style="line-height: normal">缺失</div>
                </div>
              </template>
            </el-image>
            <p style="text-align: center">开户行</p>
          </div>
          <div style="margin-right: 10px">
            <el-image
              :src="`${model.idCardZhengImgUrl}`"
              class="certImage"
              fit="fill"
              :initial-index="2"
              :preview-src-list="srcList"
              alt="缺失"
            >
              <template #error>
                <div class="image-slot">
                  <el-icon><WarnTriangleFilled /></el-icon>
                  <div style="line-height: normal">缺失</div>
                </div>
              </template>
            </el-image>
            <p style="text-align: center">法人身份证人脸面</p>
          </div>
          <div>
            <el-image
              :src="`${model.idCardFanImgUrl}`"
              class="certImage"
              fit="fill"
              :initial-index="3"
              :preview-src-list="srcList"
              alt="缺失"
            >
              <template #error>
                <div class="image-slot">
                  <el-icon><WarnTriangleFilled /></el-icon>
                  <div style="line-height: normal">缺失</div>
                </div>
              </template>
            </el-image>
            <p style="text-align: center">法人身份证国徽面</p>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="状态">
        {{ model.status_text }}
      </el-form-item>
      <el-form-item label="结果">
        <el-radio-group v-model="form.status" @change="onStatusChange">
          <el-radio :label="1">通过</el-radio>
          <el-radio :label="2">驳回</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="专员" v-if="form.status == 1">
        <div class="flex-column">
          <choose-consumer
            text="选择专员"
            @success="consumerSelectionChange"
            :list="selection_consumer_list"
          ></choose-consumer>
          <div class="selection_wrp">
            <el-tag
              style="margin: 0 10px 10px 0"
              type="info"
              v-for="item in selection_consumer_list"
              :key="item.id"
              >{{ item.name }} {{ item.mobile }}</el-tag
            >
          </div>
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-col :span="20">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder=""
          ></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import ChooseConsumer from "../component/choose_consumer.vue";

export default {
  components: {
    ChooseConsumer,
  },
  data() {
    return {
      visible: false,
      saving: false,
      loading: false,
      model: {},
      form: {},
      rules: {
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      selection_consumer_list: [],
      srcList: [],
    };
  },
  props: {
    id: Number,
  },
  emits: ["closed", "success"],
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.form = {
            id: this.id,
            status: 1,
          };
          this.loadDtl();
          this.visible = true;
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    /**
     *
     */
    onClosed() {
      this.$emit("closed");
    },

    /**
     *
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get("admin/v1/company/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0 && res.data) {
            this.model = res.data;
            this.srcList = [
              res.data.licenseImgUrl,
              res.data.kaihuImgUrl,
              res.data.idCardZhengImgUrl,
              res.data.idCardFanImgUrl,
            ];
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 专员选择回调
     */
    consumerSelectionChange(list) {
      this.selection_consumer_list = list;
    },

    /**
     * 审核结果状态更改
     */
    onStatusChange() {
      if (this.form.status == 2) {
        this.selection_consumer_list = [];
      }
    },

    /**
     *
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;

          if (
            this.selection_consumer_list &&
            this.selection_consumer_list.length > 0
          ) {
            this.form.counselorIds = this.selection_consumer_list.map(
              (o) => o.id
            );
          }
          console.log(this.form);
          this.$http
            .post("admin/v1/company/check", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.visible = false;
                this.$emit("success");
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style scoped>
.certImage {
  width: 120px;
  height: 120px;
}

.image-slot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-color-danger);
  /* font-size: 30px; */
}
.image-slot .el-icon {
  font-size: 30px;
}
</style>
